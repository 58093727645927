import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"

const ImprintPage = () => (
  <Layout>
    <SEO title="Impressum" />
    <Navigation isAbsolute={false} currentPage="impressum" inverted />

    <div className="bg-vanilla-ice">
      <section className="container py-12 pt-8 lg:py-20 lg:pt-10 copied-text">
        <h1>Impressum</h1>
        <h2 id="m46">Diensteanbieter</h2>
        <p> Heike Gäbler</p>
        <p> Harzer Straße 88</p>
        <p> 12059 Berlin</p>
        <h2 id="m56">Kontaktmöglichkeiten</h2><p><strong>E-Mail-Adresse</strong>: <a href="mailto:hallo@femalecofounders.de">hallo@femalecofounders.de</a></p>
        <p><strong>Telefon</strong>: +49 172 579 030 93</p>
        <h2 id="m169">Bildnachweise</h2><p><strong>Bildquellen und Urheberrechtshinweise</strong>:</p> <p>Johanna Ruhla<br />Unsplash</p>
        <p class="seal"><a href="https://datenschutz-generator.de/?l=de" title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken." target="_blank" rel="noopener noreferrer nofollow">Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas Schwenke</a></p>
      </section>
    </div>
  </Layout>
)

export default ImprintPage
